import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import loadable from '@loadable/component';
import SEO from "../../components/seo"
import Layout from '../../components/layout'
import BreadcrumbModule from '../../components/BreadcrumbModule/BreadcrumbModule'
const ValuationThankYou = loadable(() => import("../../components/ValuationThankYou/ValuationThankYou"));

const InstantValuation = (props) => {
    const [result, setResult] = useState(null);
    const [thankyou, setThanksyou] = useState(false);
    useEffect(() => {
        if (result === null && sessionStorage.getItem('valuationResult') != null) {
            setResult(JSON.parse(sessionStorage.getItem('valuationResult')))
        }
    })
    return (
        <Layout>
            <SEO title="Instant Valuation Results" description="Instant Valuation Results" />
            <div className="layout-padding-top">
                <BreadcrumbModule
                    parent="Contact"
                    title="Instant Valuation Results"
                />

                <Container>
                    <Row>
                        <Col lg={3}>
                        </Col>

                        <Col lg={6}>

                            <ValuationThankYou {...result} />
                        </Col>

                        <Col lg={3} className="d-md-block d-none">
                            {/* <ValuationContact tag="inner-form" /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout >
    )
}

export default InstantValuation